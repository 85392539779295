<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'companies.index'}">Companies</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Directors</a></li>
    </ol>
    <h1 class="page-header">DIRECTORS</h1>
    <panel noButton="true" title="DIRECTORS">
    <loading v-if="loading" />
    <template v-else>
      <template v-if="$can.view($store.getters.getAuthUser, 'approve-companies')">
        <fieldset>
          <legend>Add Director to {{ company.name }} Manually</legend>
          <div v-html="$error.handle(error)" />
          <form
            data-vv-scope="form"
            @submit.prevent="submitManual"
            id="director-form"
          >
            <div class="row">
              <div class="col-md-4 form-group">
                <label>First Name</label>
                <input
                  v-model="director.firstname"
                  v-validate="'required'"
                  type="text"
                  name="first_name"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form.first_name')"
                  class="help-block"
                >
                  {{ errors.first('form.first_name') }}
                </small>
              </div>
              <div class="col-md-4 form-group">
                <label>Middle Name</label>
                <input
                  v-model="director.middlename"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-md-4 form-group">
                <label>Last Name</label>
                <input
                  v-model="director.lastname"
                  v-validate="'required'"
                  type="text"
                  name="last_name"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form.last_name')"
                  class="help-block"
                >
                  {{ errors.first('form.last_name') }}
                </small>
              </div>
              <div class="col-md-6 form-group">
                <label>Email</label>
                <input
                  v-model="director.email"
                  v-validate="'required|email'"
                  name="email"
                  type="text"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form.email')"
                  class="help-block"
                >
                  {{ errors.first('form.email') }}
                </small>
              </div>
              <div class="col-md-6 form-group">
                <label>Phone Number</label>
                <phone-number
                  v-model="director.phone_number"
                  v-validate="'required|min:12'"
                  name="phone_number"
                />
                <small
                  v-if="errors.has('form.phone_number')"
                  class="help-block"
                >
                  {{ errors.first('form.phone_number') }}
                </small>
              </div>
              <div class="col-md-6 form-group">
                <label>ID Number</label>
                <input
                  v-model="director.id_number"
                  v-validate="'required'"
                  name="id_number"
                  type="text"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form.id_number')"
                  class="help-block"
                >
                  {{ errors.first('form.id_number') }}
                </small>
              </div>
              <div class="col-md-6 form-group">
                <label>Citizenship</label>
                <select
                  v-model="director.citizenship"
                  v-validate="'required'"
                  name="citizenship"
                  class="form-control"
                >
                  <option value="kenyan">
                    Kenyan
                  </option>
                  <option value="foreigner">
                    Foreigner
                  </option>
                </select>
                <small
                  v-if="errors.has('form.citizenship')"
                  class="help-block"
                >
                  {{ errors.first('form.citizenship') }}
                </small>
              </div>
              <div class="col-md-6 form-group">
                <label>Directors Photo</label>
                <input
                  ref="photo"
                  v-validate="'required'"
                  name="photo"
                  type="file"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form.photo')"
                  class="help-block"
                >
                  {{ errors.first('form.photo') }}
                </small>
              </div>
              <div class="form-group col-md-6">
                <input
                  v-model="terms"
                  v-validate="'required'"
                  name="terms"
                  type="checkbox"
                >
                <label
                  for="terms"
                  style="margin-left: 10px;"
                >I have read the <a
                  href="#"
                  @click.prevent="show_terms=true"
                >terms
                  and conditions.</a></label>
                <div
                  v-if="errors.has('form.terms')"
                  class="help-block"
                >
                  {{ errors.first('form.terms') }}
                </div>
              </div>
              <div class="col-md-12 form-group">
                <button class="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </form>
        </fieldset>
      </template>
      <template v-else>
        <fieldset v-if="$can.view($store.getters.getAuthUser, 'add-directors')">
          <legend>Add Director to {{ company.name }}</legend>
          <div v-html="$error.handle(error)" />
          <form
            data-vv-scope="form2"
            @submit.prevent="submit"
            id="director-form-2"
          >
            <div class="row">
              <div
                class="col-md-4 form-group"
                :class="{ 'has-feedback has-error': errors.has('form2.first_name')}"
              >
                <label>First Name</label>
                <input
                  v-model="director.firstname"
                  v-validate="'required'"
                  type="text"
                  name="first_name"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form2.first_name')"
                  class="help-block"
                >
                  {{ errors.first('form2.first_name') }}
                </small>
              </div>
              <div class="col-md-4 form-group">
                <label>Middle Name</label>
                <input
                  v-model="director.middlename"
                  type="text"
                  class="form-control"
                >
              </div>
              <div
                class="col-md-4 form-group"
                :class="{ 'has-feedback has-error': errors.has('form2.last_name')}"
              >
                <label>Last Name</label>
                <input
                  v-model="director.lastname"
                  v-validate="'required'"
                  type="text"
                  name="last_name"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form2.last_name')"
                  class="help-block"
                >
                  {{ errors.first('form2.last_name') }}
                </small>
              </div>

              <div
                class="form-group col-md-6"
                :class="{ 'has-feedback has-error': errors.has('form2.phone_number')}"
              >
                <label>Phone Number</label>
                <phone-number
                  v-model="director.phone_number"
                  v-validate="'required|numeric|digits:12'"
                  name="phone_number"
                />
                <small
                  v-if="errors.has('form2.phone_number')"
                  class="help-block"
                >
                  {{ errors.first('form2.phone_number') }}
                </small>
              </div>
              <div
                class="form-group col-md-6"
                :class="{ 'has-feedback has-error': errors.has('form2.email')}"
              >
                <label>Email</label>
                <input
                  v-model="director.email"
                  v-validate="'required|email'"
                  type="text"
                  class="form-control"
                  name="email"
                >
                <small
                  v-if="errors.has('form2.email')"
                  class="help-block"
                >
                  {{ errors.first('form2.email') }}
                </small>
              </div>
              <div
                class="form-group col-md-6"
                :class="{ 'has-feedback has-error': errors.has('form2.id_number')}"
              >
                <label>ID Number</label>
                <input
                  v-model="director.id_number"
                  v-validate="'required'"
                  type="text"
                  class="form-control"
                  name="id_number"
                >
                <small
                  v-if="errors.has('form2.id_number')"
                  class="help-block"
                >
                  {{ errors.first('form2.id_number') }}
                </small>
              </div>
              <div
                class="col-md-6 form-group"
                :class="{ 'has-feedback has-error': errors.has('form2.citizenship')}"
              >
                <label>Citizenship</label>
                <select
                  v-model="director.citizenship"
                  v-validate="'required'"
                  name="citizenship"
                  class="form-control"
                >
                  <option value="kenyan">
                    Kenyan
                  </option>
                  <option value="foreigner">
                    Foreigner
                  </option>
                </select>
                <small
                  v-if="errors.has('form2.citizenship')"
                  class="help-block"
                >
                  {{ errors.first('form2.citizenship') }}
                </small>
              </div>
              <div class="col-md-6 form-group">
                <label>Directors Photo</label>
                <input
                  ref="photo"
                  v-validate="'required'"
                  name="photo"
                  type="file"
                  class="form-control"
                >
                <small
                  v-if="errors.has('form2.photo')"
                  class="help-block"
                >
                  {{ errors.first('form2.photo') }}
                </small>
              </div>
              <div class="form-group col-md-6">
                <input
                  v-model="terms"
                  v-validate="'required'"
                  name="terms"
                  type="checkbox"
                >
                <label
                  for="terms"
                  style="margin-left: 10px;"
                >I have read the <a
                  href="#"
                  @click.prevent="show_terms=true"
                >terms
                  and conditions.</a></label>
                <div
                  v-if="errors.has('form2.terms')"
                  class="help-block"
                >
                  {{ errors.first('form2.terms') }}
                </div>
              </div>
              <div class="col-md-12 form-group">
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </fieldset>
        <div
          v-else
          class="alert alert-warning"
        >
          You are not allowed to add directors to this company.
        </div>
      </template>
      <modal
        :show-modal="show_terms"
        @close="show_terms=false"
      >
        <template v-slot:header>
          <h5>Terms and Conditions</h5>
        </template>
        <div>
          <account-terms />
        </div>
        <hr>
        <div class="form-group">
          <a
            href="#"
            class="btn btn-primary"
            @click.prevent="show_terms = false; terms = true;"
          >Accept Terms and
            Conditions</a>
          <a
            href="#"
            class="btn btn-danger pull-right"
            @click.prevent="show_terms = false"
          >Cancel</a>
        </div>
      </modal>
    </template>
    </panel>
  </div>
</template>

<script>
import AccountTerms from "@/views/dashboard/AccountTerms.vue";
export default {
  components: {
    AccountTerms
  },
  data() {
    return {
      loading: true,
      company: {},
      terms: false,
      show_terms: false,
      director: {
        company_id: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        phone_number: 254,
        id_number: '',
        citizenship: '',
        photo: ''
      },
      error: ''
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/companies/' + this.$route.params.id).then(response => {
        this.company = response.data.data;
        this.director.company_id = this.company.id;
        this.loading = false;
      });
    },
    postDirector(url, formData) {
      this.$axios.post(url, formData, {headers: {'content-type': 'multipart/form-data'}}).then(() => {
        this.$toastr.s('Director has been added', 'Saved');
        this.$router.push({name: 'companies.show', params: {id: this.$route.params.id}});
      }).catch(error => {
        this.error = error.response;
        
      });
    },
    getFormData() {
      const fd = new FormData();
      fd.append('photo', this.$refs.photo.files[0]);
      fd.append('company_id', this.director.company_id);
      fd.append('firstname', this.director.firstname);
      fd.append('middlename', this.director.middlename);
      fd.append('lastname', this.director.lastname);
      fd.append('email', this.director.email);
      fd.append('phone_number', this.director.phone_number);
      fd.append('id_number', this.director.id_number);
      fd.append('citizenship', this.director.citizenship);
      return fd
    },
    submit() {
      return this.$validator.validateAll('form2').then(result => {
        if (result) {
          this.postDirector('/api/v1/create/director', this.getFormData());
        }
      });
    },
    submitManual() {
      return this.$validator.validateAll('form').then(result => {
        if (result) {
          this.postDirector('/api/v1/manual-create/director', this.getFormData());
        }
      });
    }
  }
}
</script>